import { render, staticRenderFns } from "./exhibitor.vue?vue&type=template&id=6d0be324&scoped=true&"
import script from "./exhibitor.vue?vue&type=script&lang=js&"
export * from "./exhibitor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d0be324",
  null
  
)

export default component.exports