var myMixin = {
    data() {
      return {
        form:{},
        show:false
      }
    },
    methods: {
        init(val){
            this.show=true
            this.form.id=val
        },
        clear(){
            this.show=false,
            this.form= this.$options.data.call(this).form
            // this.$nextTick(()=>{
            //     this.$refs.form.resetField()
            // })
        }
    }
  }
  export default myMixin
  