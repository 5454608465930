<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商品管理</el-breadcrumb-item>
      <el-breadcrumb-item>参数列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <!-- 头部的警告区域 -->
      <el-alert
        title="主要:只允许第三级分类设置参数"
        type="warning"
        :closable="false"
        show-icon
      />
      <el-row class="a1">
        <el-col>
          <span>选择商品分类:</span>
        </el-col>
      </el-row>
      <!-- 选择商品级联菜单 -->
      <el-cascader
        v-model="selectcatekey"
        :options="catelist"
        :props="careProps"
        @change="handleChange"
      />
      <!-- tab页面去 -->
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <!-- 添加动态参数的面板 -->
        <el-tab-pane label="动态参数" name="many">
          <el-button
            type="primary"
            :disabled="isbuerzhi"
            @click="addDialogVisible = true"
          >添加参数</el-button>
          <el-table :data="manydata" border stripe>
            <!-- 这是展开航 -->
            <el-table-column type="expand">
              <template slot-scope="es">
                <!-- 循环渲染tag标签 -->
                <el-tag
                  v-for="(item, i) in es.row.attr_vals"
                  :key="i"
                  closable
                  @close="handlclose(i, es.row)"
                >
                  {{ item }}
                </el-tag>
                <!-- 输入文本框 -->
                <el-input
                  v-if="es.row.inputVisible"
                  ref="saveTagInput"
                  v-model="es.row.inputValue"
                  class="input-new-tag"
                  size="small"
                  @keyup.enter.native="handleInputConfirm(es.row)"
                  @blur="handleInputConfirm(es.row)"
                />
                <el-button
                  v-else
                  class="button-new-tag"
                  size="small"
                  @click="showInput(es.row)"
                >+ New Tag</el-button>
              </template>
            </el-table-column>
            <el-table-column type="index" />
            <el-table-column
              label="参数名称"
              prop="attr_name"
            />
            <el-table-column label="操作">
              <template slot-scope="es">
                <el-button
                  size="mini"
                  type="primary"
                  class="el-icon-edit"
                  @click="showEditDialog(es.row.attr_id)"
                >编辑</el-button>
                <el-button
                  size="mini"
                  type="danger"
                  class="el-icon-delete"
                  @click="removeParams(es.row.attr_id)"
                >删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <!-- 添加动态参数的面板 -->

        <el-tab-pane label="静态属性" name="only">
          <el-button
            type="primary"
            :disabled="isbuerzhi"
            @click="addDialogVisible = true"
          >添加属性</el-button>
          <el-table :data="onlydata" border stripe>
            <!-- 这是展开航 -->
            <el-table-column type="expand">
              <template slot-scope="es">
                <!-- 循环渲染tag标签 -->
                <el-tag
                  v-for="(item, i) in es.row.attr_vals"
                  :key="i"
                  closable
                  @close="handlclose(i, es.row)"
                >
                  {{ item }}
                </el-tag>
                <!-- 输入文本框 -->
                <el-input
                  v-if="es.row.inputVisible"
                  ref="saveTagInput"
                  v-model="es.row.inputValue"
                  class="input-new-tag"
                  size="small"
                  @keyup.enter.native="handleInputConfirm(es.row)"
                  @blur="handleInputConfirm(es.row)"
                />
                <el-button
                  v-else
                  class="button-new-tag"
                  size="small"
                  @click="showInput(es.row)"
                >+ New Tag</el-button>
              </template>
            </el-table-column>
            <el-table-column type="index" />
            <el-table-column
              label="属性名称"
              prop="attr_name"
            />
            <el-table-column label="操作">
              <template slot-scope="es">
                <el-button
                  size="mini"
                  type="primary"
                  class="el-icon-edit"
                  @click="showEditDialog(es.row.attr_id)"
                >编辑</el-button>
                <el-button
                  size="mini"
                  type="danger"
                  class="el-icon-delete"
                  @click="removeParams(es.row.attr_id)"
                >删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-card>
    <!-- 添加参数对话框 -->
    <el-dialog
      :title="'添加' + tabText"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="addcatdialogclosed"
    >
      <!-- 这是添加参数 -->
      <el-form
        ref="addFormref"
        :model="addForm"
        :rules="addFormRules"
        label-width="100px"
      >
        <el-form-item :label="tabText" prop="attr_name">
          <el-input v-model="addForm.attr_name" />
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addParams">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改参数对话框 -->
    <el-dialog
      :title="'修改' + tabText"
      :visible.sync="editDialogVisible"
      width="50%"
      @close="editcatdialogclosed"
    >
      <!-- 这是添加参数 -->
      <el-form
        ref="editFormref"
        :model="editForm"
        :rules="editFormRules"
        label-width="100px"
      >
        <el-form-item :label="tabText" prop="attr_name">
          <el-input v-model="editForm.attr_name" />
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editParams">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      catelist: [],
      careProps: {
        expandTrigger: 'hover',
        value: 'cat_id',
        label: 'cat_name',
        children: 'children'
      },
      selectcatekey: '',
      activeName: 'many',
      onlydata: [],
      manydata: [],
      //   控制显示隐藏
      addDialogVisible: false,
      addForm: {},
      //  添加表单验证对象
      addFormRules: {
        attr_name: [
          { required: true, message: '请输入参数名称', trigger: 'blur' }
        ]
      },
      editDialogVisible: false,
      //   修改的表单对象
      editForm: {},
      // 修改表单的验证规则对象
      editFormRules: {
        attr_name: [
          { required: true, message: '请输入参数名称', trigger: 'blur' }
        ]
      }
    }
  },

  computed: {
    isbuerzhi() {
      if (this.selectcatekey.length != 3) {
        return true
      }
      return false
    },
    cateId() {
      if (this.selectcatekey.length == 3) {
        return this.selectcatekey[2]
      }
      return null
    },
    // 动态计算属性
    tabText() {
      return this.activeName == 'many' ? '动态参数' : '静态属性'
    }
  },
  created() {
    this.getCateList()
  },
  methods: {
    async getCateList() {
      const { data: res } = await this.$http.get('categories')
      if (res.meta.status != 200) {
        return this.$message.error('获取商品分类失败')
      }
      this.catelist = res.data
    },
    getpareamdata() {
      this.$http
        .get(`categories/${this.cateId}/attributes`, {
          params: { sel: this.activeName }
        })
        .then(({ data: res }) => {
          res.data.forEach((item) => {
            item.attr_vals = item.attr_vals ? item.attr_vals.split(' ') : []
            // 控制文本框显示隐藏
            item.inputVisible = false
            item.inputValue = ''
          })
          console.log(res.data)

          if (this.activeName == 'many') {
            this.manydata = res.data
          } else {
            this.onlydata = res.data
          }
        })
        .catch(() => {
          this.$message.error('获取失败')
        })
    },

    // 级联选中变化
    handleChange() {
      this.getpareamdata()
    },
    handleClick() {
      this.getpareamdata()
    },
    // t添加关闭
    addcatdialogclosed() {
      this.$refs.addFormref.resetFields()
    },
    // 点击按钮添加参数
    addParams() {
      this.$refs.addFormref.validate((val) => {
        if (!val) return
        this.$http
          .post(`categories/${this.cateId}/attributes`, {
            attr_name: this.addForm.attr_name,
            attr_sel: this.activeName
          })
          .then(() => {
            this.$message.success('添加参数成功')
            this.addDialogVisible = false
            this.getpareamdata()
          })
          .catch(() => {
            this.$message.error('添加参数失败')
          })
      })
    },
    // 这是点击按钮,展示修改对话框
    showEditDialog(id) {
      // 这是查询当前参数的信息
      this.$http
        .get(`categories/${this.cateId}/attributes/${id}`, {
          params: { attr_sel: this.activeName }
        })
        .then(({ data: res }) => {
          this.editForm = res.data
        })
        .catch(() => {
          this.$message.error('获取参数失败')
        })
      this.editDialogVisible = true
    },
    // 重置修改表单
    editcatdialogclosed() {
      this.$refs.editFormref.resetFields()
    },
    // 点击按钮修改参数信息
    editParams() {
      this.$refs.editFormref.validate((val) => {
        if (!val) return
        this.$http
          .put(
            `categories/${this.cateId}/attributes/${this.editForm.attr_id}`,
            { attr_name: this.editForm.attr_name, attr_sel: this.activeName }
          )
          .then(() => {
            this.$message.success('修改成功')
            this.getpareamdata()
            this.editDialogVisible = false
          })
          .catch(() => {
            this.$message.error('修改失败')
          })
      })
    },
    // 根据id删除对应参数项
    removeParams(id) {
      this.$confirm('此操作将永久删除该参数, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$http
            .delete(`categories/${this.cateId}/attributes/${id}`)
            .then(() => {
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.getpareamdata()
            })
            .catch(() => {
              this.$message({
                type: 'error',
                message: '删除失败!'
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 文本触发
    handleInputConfirm(row) {
      if (row.inputValue.trim().length == 0) {
        row.inputValue = ''
        row.inputVisible = false
        return
      }
      row.attr_vals.push(row.inputValue.trim())
      row.inputValue = ''
      row.inputVisible = false
      this.savealvals(row)
    },
    // 点击按钮,展示文本输入框
    showInput(row) {
      row.inputVisible = true
      // 让文本框自动获得焦点
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    // 删除对应选项
    savealvals(row) {
      this.$http
        .put(`categories/${this.cateId}/attributes/${row.attr_id}`, {
          attr_name: row.attr_name,
          attr_sel: row.attr_sel,
          attr_vals: row.attr_vals.join(' ')
        })
        .then(() => {
          this.$message.success('修改参数项成功')
        })
        .catch(() => {
          this.$message.error('修改参数项失败')
        })
    },
    handlclose(i, row) {
      row.attr_vals.splice(i, 1)
      this.savealvals(row)
    }
  }
}
</script>

<style lang="less" scoped>
.a1 {
  margin: 10px 0px;
}
.el-tag {
  margin: 10px;
}
.input-new-tag {
  width: 120px;
}
</style>
