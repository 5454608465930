<template>
    <div>
        <el-dialog title="合作&展商" :visible.sync="show" width="30%">
            <el-form ref="form" :model="form" label-width="auto" :inline="false" size="normal">
                <el-form-item label="参展活动">
                    <!-- <el-input v-model="form.activityId" /> -->
                    <fuzzy-selection
                        ref="fuzzy"
                        width="100%"
                        multiple
                        :type="4"
                        :value.sync="form.activityId"
                    ></fuzzy-selection>
                </el-form-item>
                <el-form-item label="合作方编号">
                    <el-input v-model="form.partnerCode" />
                </el-form-item>
                <el-form-item label="合作方排序值">
                    <el-input v-model="form.partnerOrderNum" />
                </el-form-item>
                <el-form-item label="展商编号">
                    <el-input v-model="form.exhibitorCode" />
                </el-form-item>
                <el-form-item label="展示排序值">
                    <el-input v-model="form.exhibitorOrderNum" />
                </el-form-item>
                <el-form-item>
                    <a v-if="form.exhibitorCode && form.activityId.length">
                        <el-button type="text" @click="getDownLink" icon="el-icon-download" download="down">
                            下载展位签到码
                        </el-button>
                    </a>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="clear">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import form from "@/mixin/form.js";
import fuzzySelection from "@/components/fuzzySelection.vue";
import { extractArray } from "@/utils/methods.js";
export default {
    mixins: [form],
    components: {
        fuzzySelection,
    },
    data() {
        return {
            url: "",
        };
    },
    watch: {},
    methods: {
        init(val) {
            this.show = true;
            this.form = { ...val, activityId: extractArray(val.activityId) };
            // console.log(this.form);
            var arr = extractArray(val.activityId);
            var list = val.activityName?.map((item, index) => {
                return {
                    id: arr[index],
                    title: item,
                };
            });

            this.$nextTick(() => {
                this.$refs.fuzzy.options = list || [];
            });
        },
        async submit() {
            let { data: res } = await this.$http.post("/admin/Enterprise/editActivity", {
                ...this.form,
                activityId: this.form.activityId.toString(),
            });
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.show = false;
                this.$emit("getlist");
            }
        },
        async getDownLink() {
            let {
                data: { data },
            } = await this.$http.get("/admin/Enterprise/getExhibitorQrCode", {
                params: { enterpriseId: this.form.id },
            });

            // // console.log(data);
            // // this.url = `https://www.yfchuhai.com/home/Activity/downPoster?url=${data?.url || ""}` || "";
            // // this.url = data?.url || "";
            // return;
            location.href = `https://www.yfchuhai.com/home/Activity/downPoster${this.$queryParams(data) || ""}`;
        },
    },
};
</script>

<style lang="less" scoped></style>
